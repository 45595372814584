@import 'scss/components_base';

.placeholder {
  @include fit-parent;
  width: 100%;
  height: 100%;
  z-index: 1;
  rect {
    width: 100%;    
    height: 100%;
    fill: var(--color-off-white);
    // transform: translate3d(0, 0, 0);
    // will-change: transform;
    &.transform {
      fill: var(--placeholder-transform-background);
    }
  }
}

// .mobileImage,
// .desktopImage {
//   // opacity: 0;
//   // transition: opacity 0s;
  
//   // -webkit-user-select: none;
//   // -khtml-user-select: none;
//   // -moz-user-select: none;
//   // -o-user-select: none;
//   // user-select: none;
// }
// .imageLoaded {
//   // opacity: 1;
//   // user-select: none;
// }
// // .desktopImageCanSwap {
// //   display: none !important;
// // }
// // @media (orientation: landscape) {
// //   .desktopImageCanSwap {
// //     display: block !important;
// //     user-select: none;
// //   }
// //   .mobileImage {
// //     display: none !important;
// //     user-select: none;
// //   }
// // }
