@import 'scss/components_base';
.container {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  // overflow: hidden;
}

.videoStateIcon {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  // text-align: center;
  // transform-origin: center center;

  font-size: 14px;
  line-height: 0;
  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;
  color: white;
  @include md_ {
    font-size: rem-property(69px);
    line-height: 1.3;
    letter-spacing: 1px;
  }
}
.videoStateIconHidden {
  display: none;
}
.poster {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: -1;
  grid-row-end: -1;
  // position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s;
}

.posterHiding {
  opacity: 0;
}
.video {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: -1;
  grid-row-end: -1;

  // cursor: none;
}
.aspectContainer {
  position: relative;
}
.darkener {
  background: rgba(black, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
  &::after {
    content: 'PLAY';
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 2em;
  }
}
