// @import '../constants';

@mixin container($property:margin) {
    @if ($property == margin) or ($property == padding) {
      #{$property}-left: var(--standard-inset);
      #{$property}-right: var(--standard-inset);
    } @else {
      #{$property}: var(--standard-inset);
    }
  // @include xxl {
  //   @if ($max-width) {
  //     max-width: $max-width;
  //     margin-left: calc(50vw - #{$max-width / 2});
  //   } @else {
  //     #{$property}-left: var(--inset);
  //     #{$property}-right: var(--inset);
  //   }
  // }
}

@function columns($requested-columns,
                  $parent-columns: var(--standard-columns),
                  $gutter-multiplier: 2,
                  $unit: '%') {
  @if $gutter-multiplier == 0 {
    @return calc((100% * ((var(--standard-columns) / $parent-columns) * ($requested-columns / var(--standard-columns)))));
  } @else {
    // TODO think we've lost parent alignment
    @return calc((100% * ((var(--standard-columns) / $parent-columns) * ($requested-columns / var(--standard-columns)))) - (var(--standard-gutter) * $gutter-multiplier));
  // @return calc((100% * ((var(--standard-columns) / $parent-columns) * ($requested-columns / var(--standard-columns)))));
  }
}

@mixin gutters($property: margin, $multiplier: 1) {
  @if ($property == margin) or ($property == padding) {
    #{$property}-left: calc(var(--standard-gutter) * $multiplier);
    #{$property}-right: calc(var(--standard-gutter) * $multiplier);
  } @else {
    #{$property}: calc(var(--standard-gutter) * $multiplier);
  }
  
}

// Standard responsive grid with gaps, different by breakpoint.
// TODO use these for grids?

@mixin grid-standard {
  display: grid;
  grid-template-columns: repeat(var(--standard-cols), minmax(0, 1fr));
  @include grid-standard-col-gaps;
}

@mixin grid-standard-col-gaps {
  column-gap: var(--standard-gutter);
}

@mixin fillGrid {
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
}


// TODO talk with Joris about these.?
// TODO make these mixins?
// .u-content-width {
//   @include content-width;
// }
// .u-grid-standard {
//   @include grid-standard;
// }

// .u-no-body-scroll {
//   overflow: hidden;
//   position: fixed;
//   width: 100%;
//   height: 100%;
// }

