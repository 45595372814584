html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: normal;
}

fieldset {
  border: 0;
}

body {
  position: relative;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

input {
  border-radius: 0; // This fixes rounded border on iOS/Safari
  font: inherit;
  margin: 0; // fixes wonky inline buttons on safari
}

button {
  border: none;
  cursor: pointer;
  font: inherit;
}

img {
  width: 100%;
}
a {
  // @include unstyled-link;
  color: inherit;
  @include clear-anchor-style;
}

nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
