// Misc utilities

@mixin visuallyHidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
}

// Page initially loads with 'no-js' class on the body.
// As soon as JS loads (via a script in the header),
// it should replace that class with 'js'
@mixin no-js-hide {
  .no-js & {
    display: none !important;
  }
}
@mixin js-hide {
  .js & {
    display: none !important;
  }
}

@mixin list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

@mixin fit-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin fixed {
  position: fixed;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

// TODO make these mixins?
// .u-bounce-remover {
//   // ADDING position:fixed on mobile for this project messes with the safari ios dock menu

//   @include md_ {
//     overflow: hidden;
//     position: fixed;
//     height: var(--vh);
//     width: 100%;
//   }
// }

// .u-no-overflow-x {
//   // having overflow will act as a scollstopper and can't call "window.scrollTo(0,0)"
//   // we need to toggle this
//   overflow-x: hidden;
// }
// .u-remove-animation {
//   transition: none !important;
// }


// .sr-only {
//   @include visuallyHidden;
// }

// .u-hidden {
//   display: none !important;
// }

// .u-no-js-hide {
//   @include no-js-hide;
// }
// .u-js-hide {
//   @include js-hide;
// }
