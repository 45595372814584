@import 'scss/components_base';

.container {
  @include _sm {
    // .container
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include fixed;
    opacity: 0;
    pointer-events: none;
    @include gutters(padding, $multiplier: 2);
    @include gutters(padding-top, $multiplier: 2);
    @include gutters(padding-bottom, $multiplier: 2);
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding-top: calc(var(--header-height) + 3rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .nav {
      .list {
        // @include type_sm_menu;
        font-size: rem-property(18px);
        margin-bottom: 2em;
        padding-bottom: 1px;
      }
      .listItem {
        margin-bottom: 1em;
        a {
          @include opacity-anchor-style;
          display: inline-block;
        }
      }
    }
  }
  @include md_ {
    // .container
  }
}

.nav {
  @include md_ {
    // .nav
    top: 0;
    left: 0;
    right: 0;

    @include fixed;
    @include container;
    // @include gutters(padding, $multiplier: 2);
    @include gutters(padding-top, $multiplier: 2);
    @include gutters(padding-bottom, $multiplier: 2);
    z-index: 100;
    // transition: transform 0.5s;
    // transform: translateY(0);

    // TODO this causes the animation to lag
    //      maybe layer placeholder helps
    mix-blend-mode: difference;
    @include type_base;
    transition: opacity 0.5s;
    will-change: opacity;
    opacity: 0;
    &.loaded {
      opacity: 1;
    }
    a {
      color: white;
    }
    .list {
      display: flex;
      // justify-content: space-between;
    }
    .listItem {
      width: columns(3);
      position: relative;
      top: 0.15em;
      @include gutters;
      &.listItemContact {
        width: columns(2);
      }
      a {
        cursor: pointer;
        @include opacity-anchor-style;
        display: inline-block;
      }
    }
  }
  // &:not(.navScrollActivated) {
  //   transform: translateY(-101%);
  // }
}

.menuToggle {
  @include _sm {
    mix-blend-mode: difference;
    color: var(--color-white);
    top: 0;
    right: 0;
    left: 0;
    @include fixed;
    @include gutters(padding, $multiplier: 2);
    @include gutters(padding-top, $multiplier: 2);
    @include gutters(padding-bottom, $multiplier: 2);
    z-index: 101;
    text-align: right;
    @include type_sm_menu;
    span {
      position: relative;
      top: 0.05em;
    }
    &.showMenu {
      // color: var(--color-white);
    }
  }
}

.headerPlaceholder {
  height: calc(var(--header-height));
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  z-index: 99;
}

.headerPlaceholderBlank {
  background-color: var(--color-primary);
}
