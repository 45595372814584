// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-primary: #000000;
  --color-white: #ffffff;
  --color-off-white: #eeeeee;

  --color-off-white: #fcfcfc;
  --placeholder-transform-background: #000000;
}

