@use 'sass:math';
@import 'scss/components_base';



.container {
  @include md_ {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    // min-height: calc(var(--vh) - (var(--standard-gutter) * 2) - var(--header-height));
    &.home {
      height: 100vh;
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
    .mo__er {
      position: relative;
    }
    .__ll__ {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      will-change: height;
      transform: translate3d(0, 0, 0);
      // Set in js min-height: calc($px-val-of-logo * var(--logo-ratio));
    }
  }
  @include _sm {
    display: flex;
    .mollerRotated {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
