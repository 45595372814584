// Base styles that aren't a component, a utility, a reset thing, etc...

html {
  overscroll-behavior: none;
  scroll-behavior: auto;
  @media (prefers-reduced-motion: no-preference) {
  }
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
  }

  // @include md_ {
  //   ::-webkit-scrollbar {
  //     width: 0 !important;
  //   }
  //   overflow: -moz-scrollbars-none;
  // }
}
body {
  opacity: 0;
  transition: opacity 0.1s;
  overscroll-behavior: none;

  //get rid of the safari bounce
  @include md_ {
    // overflow: hidden;
    // position: fixed;
    // width: 100%;
    // height: 100%;
  }

  &.is-fonts-loaded {
    opacity: 1;
  }
}
abbr {
  text-decoration: none;
  border-bottom: none;
}

button,
a {
  &:focus {
    outline: 0;
  }
}
//
.pageNotFound__container {
  background: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageNotFound__title {
  padding: rem-property(28px);
}
