
$root-font-base-size: 14px;
$root-font-base-line-height: 1.2;
$root-font-min-size: 10px;
$root-font-max-size: 100px;
$design-width: 1440px;


$breakpoint-sm: 500px;
$breakpoint-md: 800px;
$breakpoint-lg: 1220px;
$breakpoint-xl: 1440px;
$breakpoint-xxl: 1600px;

$standard-opacity: .5;

$svg-height: 495;
$svg-width: 1404;