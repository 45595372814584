// @use 'sass:math';
@import 'vars/constants';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern=1';
  -webkit-font-kerning: normal;
  -moz-font-kerning: normal;
  font-feature-settings: 'kern';
  font-kerning: normal;

  font-style: normal;
  font-family: var(--font-family-primary);
  line-height: $root-font-base-line-height;
  font-size: $root-font-base-size;

  @include md_ {
    @include vw-font-size(
      $root-font-base-size,
      $min-px-size: $root-font-min-size,
      $max-px-size: $root-font-max-size,
      $design-width: $design-width
    );
  }
}

body {
  
}

p {
  margin-bottom: $root-font-base-line-height * 1em;
}
