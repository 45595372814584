@import 'scss/components_base';

.menu,
.contact {
  transition: opacity 0.2s;
  &.exit,
  &.exitActive,
  &.exitDone {
    opacity: 0;
  }
  &.enter {
    opacity: 0;
  }
  &.enterActive,
  &.enterDone {
    opacity: 1;
  }
}

.fade {
  transition: opacity 0.6s;
  &.exit,
  &.exitActive,
  &.exitDone {
    opacity: 0;
  }
  &.enter {
    opacity: 0;
    @include fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  &.enterActive,
  &.enterDone {
    opacity: 1;
  }
}

.next {
  // annoyingly there is no in-out animation
  // so we need to hack it with simultanious 
  transition: opacity 0.6s;

  &.exit,
  &.exitActive,
  &.exitDone {
    transition: opacity 0.1s;
    z-index: 90;
    transition-delay: 0.6s;
    height: 100vh !important;
  }
  &.enter {
    opacity: 0;
  }
  &.enterActive,
  &.enterDone {
    opacity: 1;
  }
}

.filtering {
  article > *:not(footer) {
    transition: opacity 0.6s;
  }
  
  &.exit {
    article > *:not(footer) {
      opacity: 0;
    }
  }

  &.exit,
  &.exitActive,
  &.exitDone {
    article > *:not(footer) {
      opacity: 0;
    }
  }
  &.enter {
    article > *:not(footer) {
      opacity: 1;
    }
  }
  &.enterActive,
  &.enterDone {
    article > *:not(footer) {
      opacity: 1;
    }
  }
}
