@use 'sass:math';

@function em-property($px-value, $parent-font-px-size: $root-font-base-size) {
  @return (math.div($px-value, $parent-font-px-size) * 1em);
}

@function rem-property($px-value) {
  @return (math.div($px-value, $root-font-base-size) * 1rem);
}

@mixin vw-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-width) {
  $unitless-vw-size: math.div($px-size, $design-width) * 100;
  $scaled-size: #{$unitless-vw-size + 'vw'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}
@mixin vh-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-height) {
  $unitless-vh-size: math.div($px-size, $design-height) * 100;
  $scaled-size: #{$unitless-vh-size + 'vh'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}

/* 
  CUSTOM TO MOLLER
*/

@mixin type_base {
  font-size: 1rem;
  line-height: 1;
}

@mixin type_sm_menu {
  font-size: rem-property(18px);
}

@mixin type_sub {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: max(11px, rem-property(11px));
  line-height: 1.3;
}

@mixin type_content_indent {
  &:not(:first-of-type) {
    text-indent: 3em;
  }
  &:not(:last-of-type) {
    margin-bottom: 0;
  }
}

@mixin type_content_1 {
  @include _sm {
    font-size: rem-property(14px);
    line-height: 1.3;
  }
  @include md_ {
    font-size: rem-property(17px);
    line-height: 1.3;
  }
}

@mixin type_content_2 {
  @include _sm {
    font-size: rem-property(13px);
    line-height: 1.3;
  }
  @include md_ {
    font-size: rem-property(20px);
    line-height: 1.3;
  }
}

@mixin type_content_3 {
  @include _sm {
    font-size: rem-property(13px);
    line-height: 1.3;
  }
  @include md_ {
    font-size: rem-property(24px);
    line-height: 1.2;
  }
}

@mixin type_heading_1 {
  @include _sm {
    font-size: rem-property(24px);
    line-height: 1.2;
  }
  @include md_ {
    font-size: rem-property(43px);
    line-height: 1;
    letter-spacing: -0.01em;
  }
}

// @mixin type_numerals {
//   font-family: var(--font-primary);
//   font-style: normal;
//   font-weight: 400;
//   // TODO font-size: 47px;
//   line-height: 1.15;
//   @include md_ {
//     // TODO font-size: rem-property(47px);
//     line-height: 1.15;
//   }
// }

@mixin quote-large {
}
@mixin quote-large-credit {
}
@mixin quote-large-credit-italic {
  font-style: italic;
}

@mixin quote-medium {
}
@mixin quote-small {
}
// @mixin side-slug {
//   font-style: normal;
//   font-weight: normal;

//   font-size: 9px;
//   line-height: 1.2;
//   letter-spacing: 0.3px;

//   @include md {
//     font-size: rem(12);
//     line-height: 1.2;
//     letter-spacing: 0.3px;
//   }
// }
