.pageSwapper {
  width: 100%;
}

.pageTransitioning {
  .pageSwapper {
    position: fixed;
    top: 0;
    left: 0;
  }
}