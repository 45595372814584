@import '../scss/components_base';

.siteContainer {
  min-height: 100vh;
}

.main {
  background-color: var(--color-white);
  article {
    min-height: 100vh;
    position: relative;
    z-index: 10;
    background-color: var(--color-white);

    // prevent header placeholdler and margin-bottoms conflict
    &::after {
      content: '';
      display: block;
      height: 1px;
    }
  }
}