@import 'scss/components_base';

.container {
  @include type_base;
  line-height: 1.285;
  a {
    @include opacity_anchor_style;
  }
  .socialMedia {
    a {
      display: block;
    }
  }
  &.reverse {
    color: var(--color-white);
  }
  &:not(.reverse) {
    @include md_ {
      // &:not(.reverse)
      background-color: var(--color-white);
      .inner {
        background-color: var(--color-white);
      }
    }
  }
  @include _sm {
    // .container
    // @include type_sm_menu;
    font-size: rem-property(13px);
    margin-bottom: 10vh;
  }
  @include md_ {
    // .container
    min-height: 100vh;
    z-index: 99;
    // @include fixed;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    
    .inner {
      position: sticky;
      top: 0;
      z-index: 50;
      height: var(--contact-inner-height);
      @include gutters(padding-bottom, $multiplier: 2);
      @include container(padding);
      > * {
        margin-left: columns(9, $gutter-multiplier: 0);
        @include gutters(padding);
      }
    }
    .footer {
      height: calc((100vh - var(--contact-inner-height) - (var(--standard-gutter) * 1)) * 2);
      background-color: var(--color-white);
      position: relative;
      overflow: hidden;
    }
    .media {
      @include fixed;
      // top: var(--contact-inner-height);
      top: 0;
      left: 0;
      right: 0;
      height: 250vh;
      figure {
        // @include fit-parent;
        img {
          object-fit: contain;
          object-position: top center;
        }
      }
    }
    .clip {
      @include fit-parent;
      background-color: var(--color-primary);
      z-index: 5;
      clip: rect(100vh, 100vw, 200vh, 0);
      transition: clip 1000ms cubic-bezier(.645, .045, .355, 1);
      overflow: hidden;
      &.unclip {
        clip: rect(0vh, 100vw, 200vh, 0);
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    .rect {
      // height: var(--contact-image-height);
      height: calc((100vh - var(--contact-inner-height)));
      min-width: 100%;
      @include gutters(padding, $multiplier: 2);
      @include gutters(padding-top, $multiplier: 2);
      @include gutters(padding-bottom, $multiplier: 2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
  }
}