@import '../../scss/components_base';

.container {
  font-size: 16px;
  line-height: 1em;;
  position: fixed;
  right: 0;
  top: 50%;
  white-space: nowrap;
  z-index: 3000;
  transform: rotateZ(90deg) translate3d(3.3em, 0, 0);
  transform-origin: top right;
}
.text {
  font-style: normal;
  // font-weight: 600;
  padding: 1em;
  background: black;
  color: white;
  font-weight: 700;
  letter-spacing: 0.1em;
}
